// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const CalendarListItem = ({ description, isLoading, order }) => {

	return (
		<>

		<div className="CalendarListItem">

			<h5 className="CalendarListItem__description blue semi-bold">{! isLoading ? description : <BTDSkeleton />}</h5>

			<p className='CalendarListItem__company blue'>{! isLoading ? (order ? order.trading_name : '') : <BTDSkeleton />}</p>

		</div>

		</>
	)
}

CalendarListItem.propTypes = {
	isLoading: PropTypes.bool,
	order: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

CalendarListItem.defaultProps = {
	isLoading: false,
	trading_name: null,
	order: {}
}

export default CalendarListItem;