// Packages
import PropTypes from 'prop-types'

// Components
import UserForm from 'modules/Users/components/Form';

// Other
import useApi from 'hooks/useApi';

const UserCreateScreen = ({ onClose, onSubmit }) => {

    const { post: createItem, errors } = useApi('/users',  false)

	const submit = async (values) => {

        if( await createItem(values, 'User created!') ) {
    
            onSubmit();
        }
    }


    return(
        <UserForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

UserCreateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

UserCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default UserCreateScreen;