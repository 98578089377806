// Packages
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import PublicLayout from 'layouts/Public';

// Other
import '../styles.css';
import useApi from 'hooks/useApi';

const PasswordResetScreen = () => {

    const { data, post: submit } = useApi('/reset-password', false);

    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();

    useEffect(() => {

        const queryString = window.location.search;

        const urlSearchParams = new URLSearchParams(queryString);
    
        setToken(urlSearchParams.get('token'));
    }, [])

    return(
        <PublicLayout>
            <div className='LoginScreen'>

                <div className='LoginScreen__main'>

                    <h3 style={{marginBottom: '20px'}}>Reset Password</h3>

                    <BTDTextField
                        label='Email address'
                        onChange={(value) => setEmail(value)}
                        style={{backgroundColor: '#fff'}}
                        value={email}
                    />

                    <BTDTextField
                        label='New Password'
                        onChange={(value) => setPassword(value)}
                        type='password'
                        style={{backgroundColor: '#fff'}}
                        value={password}
                    />

                    <BTDTextField
                        label='Confirm Password'
                        onChange={(value) => setConfirmPassword(value)}
                        type='password'
                        style={{backgroundColor: '#fff'}}
                        value={confirmPassword}
                    />
                    {(password !== '' && confirmPassword !== '') && password !== confirmPassword &&
                        <p className='red' style={{margin: '-15px 0 20px 0'}}>Passwords must match!</p>
                    }
                    {(password !== '' && confirmPassword !== '') && password === confirmPassword &&
                        <p className='green' style={{margin: '-15px 0 20px 0'}}>Passwords match!</p>
                    }
                    
                    <BTDButton
                        disabled={password !== confirmPassword}
                        formButton={true}
                        label='Submit >'
                        onClick={async () => {
                            if( await submit({token: token, email: email, password: password, password_confirmation: confirmPassword}, 'Password reset!') ) {
                                navigate('/login');
                            }
                        }}
                    />
                </div>
            </div>
        </PublicLayout>

    )

}

export default PasswordResetScreen;