// Packages
import { Link } from 'react-router-dom';
import ListItem from 'modules/Calendar/components/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const CalendarList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='CalendarList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No items to show here</h3>
		)
	}


	// Data State
	return (
		<div className='CalendarList'>

			{items.map((item, index) => {

				return (
						<Link key={index} to={`/admin/bookings/${item.order_id}`}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}
	
		</div>
	)
}

CalendarList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

CalendarList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default CalendarList;