// Packages
import PropTypes from 'prop-types';

const CrossCircleIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="CrossCircleIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg height={height} width={width} viewBox="0 0 20 20" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="x-mark" fill={colour} fillRule="nonzero">
                        <path d="M10,20 C4.48593141,20 0,15.5140686 0,10 C0,4.48593141 4.48593141,0 10,0 C15.5140686,0 20,4.48593141 20,10 C20,15.5140686 15.5140686,20 10,20 Z M10,1.42852781 C5.27374266,1.42852781 1.42852781,5.27374266 1.42852781,10 C1.42852781,14.7262573 5.27374266,18.5714722 10,18.5714722 C14.7262573,18.5714722 18.5714722,14.7262573 18.5714722,10 C18.5714722,5.27374266 14.7262573,1.42852781 10,1.42852781 Z" id="Shape"></path>
                        <path d="M13.5714722,14.2857361 C13.3886719,14.2857361 13.2058716,14.2160034 13.0664063,14.0763855 L5.92361449,6.93359375 C5.64453125,6.65451051 5.64453125,6.20254516 5.92361449,5.92361449 C6.20254516,5.64468383 6.65466309,5.64453125 6.93359375,5.92361449 L14.0763855,13.0664063 C14.3554688,13.3454895 14.3554688,13.7974548 14.0763855,14.0763855 C13.9369202,14.2160034 13.7542725,14.2857361 13.5714722,14.2857361 L13.5714722,14.2857361 Z" id="Path"></path>
                        <path d="M6.42852781,14.2857361 C6.24572754,14.2857361 6.06307984,14.2160034 5.92361449,14.0763855 C5.64453125,13.7974548 5.64453125,13.3453369 5.92361449,13.0664063 L13.0664063,5.92361449 C13.3454895,5.64453125 13.7974548,5.64453125 14.0763855,5.92361449 C14.3553162,6.20254516 14.3554688,6.65466309 14.0763855,6.93359375 L6.93359375,14.0763855 C6.79412844,14.2160034 6.61132812,14.2857361 6.42852781,14.2857361 L6.42852781,14.2857361 Z" id="Path"></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

CrossCircleIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CrossCircleIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default CrossCircleIcon;