// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import SettingsListScreen from "modules/Settings/screens/List"


const SettingsRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<SettingsListScreen />}/>
		</Routes>
	)
}

export default SettingsRoutes;