// Packages
import { useState } from 'react';

// Components
import AdminLayout from 'layouts/Admin';
import CouponCreateScreen from '../Create';
import BTDButton from 'components/BTDUI/BTDButton';
import CouponList from 'modules/Coupons/components/List';

// Other
import useApi from 'hooks/useApi';

const CouponScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/coupons?with=placements');

    const [formActive, setFormActive] = useState(false);

    return(
        <AdminLayout>

            <h2 style={{margin: '40px 0'}}>Coupons</h2>

            <p style={{marginBottom: '30px'}}>It is not possible to edit a coupon once created. If you need to change something about a coupon, delete the existing one and create it newly.</p>

            <BTDButton
                label='Add Coupon'
                onClick={() => {setFormActive(true)}}
                size='small'
                style={{margin: '0 0 15px auto'}}
            />

            <CouponList
                isLoading={isLoading}  
                items={items ?? []} 
                onChange={() => {onChange()}}
            />

            {formActive &&
                <CouponCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </AdminLayout>
    )

}

export default CouponScreen;