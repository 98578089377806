// Packages
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';

// Components
import BookingForm from 'modules/Bookings/components/Form';
import PublicLayout from 'layouts/Public';

// Other
import useApi from 'hooks/useApi';

const BookingApproveScreen = () => {

    const location = useLocation();

    const parts = location.pathname.split('/');
    const linkId = parts[parts.length - 1];

    const { data: item, isLoading } = useApi('/orders/' + linkId + '/approve?with=country,bookings,coupon');
    const { put: updateItem, errors } = useApi('/orders/' + item.id + '/approve', false);

	const submit = async (values) => {

        await updateItem(values, 'Booking approved!', '/thank-you')
    }

    return(

        <PublicLayout>

            {! isLoading &&
                <BookingForm 
                    admin={false}
                    errors={errors} 
                    item={item} 
                    onSubmit={submit} 
                    type='approve' 
                />
            }


        </PublicLayout>
    )
}

BookingApproveScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BookingApproveScreen.defaultProps = {
    itemId: null,
}

export default BookingApproveScreen;