// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import UserListScreen from "modules/Users/screens/List"


const UserRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<UserListScreen />}/>
		</Routes>
	)
}

export default UserRoutes;