// Packages
import { useNavigate } from 'react-router-dom';

// Components
import BookingForm from 'modules/Bookings/components/Form';
import PublicLayout from 'layouts/Public';

// Other
import useApi from 'hooks/useApi';

const PublicBookingCreateScreen = () => {

    const navigate = useNavigate();

    const { post: createItem, errors } = useApi('/orders/submit',  false)

	const submit = async (values) => {

        if( await createItem(values, 'Booking created!') ) {

            navigate('/thank-you');
        }
    }

    return(

        <PublicLayout>

            <BookingForm 
                admin={false}
                errors={errors} 
                onSubmit={submit} 
                type='create' 
            />

        </PublicLayout>

    )
}

export default PublicBookingCreateScreen;