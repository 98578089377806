// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import PlacementEditScreen from 'modules/Placements/screens/Edit';
import Currency from 'components/BTDUI/Currency';
import EditCircleIcon from 'components/BTDUI/icons/EditCircleIcon';
import CrossCircleIcon from 'components/BTDUI/icons/CrossCircleIcon';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const PlacementListItem = ({ description, id, index, isLoading, onChange, rate }) => {

	const { destroy } = useApi('/placements/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		if( await destroy('placement', 'Placement Deleted!') ) {

			onChange();
		}

    }

	return (
		<>
		<div className="PlacementListItem card">

			<p className='PlacementListItem__index'>{index !== '' ? index + 1 : ''}</p>

			<p style={{marginRight: '15px'}}>{! isLoading ? description : <BTDSkeleton />}</p>

			<p className='PlacementListItem__rate'>{! isLoading ? <Currency value={rate} /> : <BTDSkeleton />}</p>

			<span onClick={() => {setFormActive(true);}}><EditCircleIcon height='20px' style={{cursor: 'pointer', transform: 'translateY(1px)'}} /></span>
			
			<span onClick={() => {deleteItem()}}><CrossCircleIcon colour='#e02323' height='20px' style={{cursor: 'pointer', transform: 'translateY(1px)'}} /></span>

		</div>

		{formActive &&
			<PlacementEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
			/>
		}
		</>
	)
}

PlacementListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
}

PlacementListItem.defaultProps = {
	id: null,
	index: '',
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
}

export default PlacementListItem;