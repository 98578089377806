// Packages
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import CouponEditScreen from 'modules/Coupons/screens/Edit';
import EyeIcon from 'components/BTDUI/icons/EyeIcon';
import CrossCircleIcon from 'components/BTDUI/icons/CrossCircleIcon';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import BTDTooltip from 'components/BTDUI/BTDTooltip';

const CouponListItem = ({ code, id, is_active, isLoading, onChange, placements, placement_mode, rate }) => {

	const { data, destroy, errors, put } = useApi('/coupons/' + id, false);

	const [formActive, setFormActive] = useState(false);
	const [dropdownActive, setDropdownActive] = useState(false);
	const [active, setActive] = useState(is_active);

	const deleteItem = async () => {

		if( await destroy('coupon', 'Coupon Deleted!') ) {

			onChange();
		}

    }

	useEffect(() => {

		if(data && Object.keys(data).length > 0) {

			setActive(data.is_active);
		}
	}, [data])

	return (
		<>
		<div className="CouponListItem card">
			
			<div className='CouponListItem__switch'>
				<BTDTooltip text={active ? 'Disable' : 'Activate'}>
					<BTDSwitch
						checked={active}
						error={errors && errors.is_active ? errors.is_active : null} 
						onChange={(value) => {put({is_active: value})}} 
						style={{marginBottom: '0'}}
					/>
				</BTDTooltip>
			</div>

			<p className='CouponListItem__code' style={{marginRight: '15px'}}>{! isLoading ? code : <BTDSkeleton />}</p>

			<div  className='CouponListItem__placements'>
				<div onMouseEnter={() => setDropdownActive(true)} onMouseLeave={() => setDropdownActive(false)} style={{alignItems: 'center', display: 'flex', paddingRight: '15px', position: 'relative'}}>
					{! isLoading ? (placement_mode + ' placements') : <BTDSkeleton />}
					{! isLoading && placement_mode === 'selected' &&
						<>
						<EyeIcon colour='#0C3580' height='20px' style={{cursor: 'pointer', marginLeft: '8px', transform: 'translateY(2px)'}}width='20px'/>
						{dropdownActive &&
							<div className='CouponListItem__dropdown'>
								{placements && placements.map((placement) => (
									<p>{placement.description}</p>
								))}
							</div>
						}
						</>
					}
				</div>
			</div>

			<p className='CouponListItem__rate'>{! isLoading ? rate + ' %' : <BTDSkeleton />}</p>
			
			<span className='CouponListItem__delete' onClick={() => {deleteItem()}}><CrossCircleIcon colour='#e02323' height='20px' style={{cursor: 'pointer', transform: 'translateY(1px)'}} /></span>

		</div>

		{formActive &&
			<CouponEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
			/>
		}
		</>
	)
}

CouponListItem.propTypes = {
	code: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	is_active: PropTypes.bool,
	isLoading: PropTypes.bool,
	onChange: PropTypes.func,
	placements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	placement_mode: PropTypes.string,
	rate: PropTypes.number
}

CouponListItem.defaultProps = {
	code: '',
	id: null,
	is_active: true,
	isLoading: false,
	onChange: () => {'onChange is not set!'},
	placements: [],
	placement_mode: '',
	rate: 0
}

export default CouponListItem;