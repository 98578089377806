// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDRadioField from 'components/BTDUI/fields/BTDRadioField';
import BTDMultiselect from 'components/BTDUI/fields/BTDMultiselect';

// Other
import useApi from 'hooks/useApi';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';


const CouponForm = ({ errors, item, onClose, onSubmit, type, }) => {

    const {data: placements } = useApi('/placements');

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [magazine, setMagazine] = useState([]);
    const [website, setWebsite] = useState([]);
    const [edm, setEdm] = useState([]);
    const [onlineArticles, setOnlineArticles] = useState([]);
    

    // Load Item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);

            if( item.placements && item.placements.length > 0 ) {

                let mag = [];
                let web = [];
                let elec = [];
                let oa = [];

                item.placements.forEach(function(placement) {

                    switch (placement.media) {
                        case 'Magazine':
                            mag.push(placement.id)
                            break;
                        case 'Website':
                            web.push(placement.id)
                            break;
                        case 'EDM':
                            elec.push(placement.id)
                            break;
                        case 'Online Articles':
                            oa.push(placement.id)
                            break;
                        default:
                            break;
                    }

                    setMagazine(mag);
                    setWebsite(web);
                    setEdm(elec);
                    setOnlineArticles(oa);
                })
            }
		}

	}, [item]);

    const submit = () => {

        let newPlacements = [];

        if( data.placement_mode === 'selected' ) {

            newPlacements = [...magazine, ...website, ...edm, ...onlineArticles]
        }
        else {

            newPlacements = Object.values(placements).flatMap(array =>
                array.map(obj => obj.id)
            );
        }

        onSubmit({...changedData, placements: newPlacements})
    }

    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create coupon' : 'Edit coupon'}</h3>

            <BTDTextField
                error={errors && errors.code ? errors.code : null} 
                label='Code'
                onChange={(value) => {
                    setData({...data, code: value.replace(' ', '')});
                    setChangedData({...changedData, code: value.replace(' ', '')});
                }} 
                required={true}
                value={data.code ?? ''} 
            />

            <BTDNumberField
                error={errors && errors.rate ? errors.rate : null} 
                label='Rate (%)'
                onChange={(value) => {
                    setData({...data, rate: value});
                    setChangedData({...changedData, rate: value});
                }} 
                required={true}
                step={0.1}
                value={data.rate ?? ''} 
            />

            <BTDSwitch
                checked={data.is_active ?? true}
                error={errors && errors.is_active ? errors.is_active : null} 
                label='Is this coupon active?'
                onChange={(value) => {
                    setData({...data, is_active: value});
                    setChangedData({...changedData, is_active: value});
                }} 
            />

            <BTDRadioField
                label='Placements'
                onChange={(value) => {
                    setData({...data, placement_mode: value});
                    setChangedData({...changedData, placement_mode: value});
                    
                }} 
                options={[
                    {value: 'all', label: 'Coupon applies to all placements', colour: 'blue'},
                    {value: 'selected', label: 'Coupon applies to selected placements', colour: 'blue'},
                ]}
                value={data.placement_mode ?? 'all'} 
            />

            {data.placement_mode === 'selected' && 
            <>

                <div className='form__grid-2'>
                    <BTDMultiselect
                        label='Magazine'
                        onChange={(value) => {setMagazine(value)}} 
                        options={placements['Magazine']}
                        optionLabel={['description']}
                        value={magazine ?? ''} 
                    />
                
                    <BTDMultiselect
                        label='Website'
                        onChange={(value) => {setWebsite(value)}} 
                        options={placements['Website']}
                        optionLabel={['description']}
                        value={website ?? ''} 
                    />
                </div>
            
                <div className='form__grid-2'>
                    <BTDMultiselect
                        label='EDM'
                        onChange={(value) => {setEdm(value)}} 
                        options={placements['EDM']}
                        optionLabel={['description']}
                        value={edm ?? ''} 
                    />
                
                    <BTDMultiselect
                        label='Online Articles'
                        onChange={(value) => {setOnlineArticles(value)}} 
                        options={placements['Online Articles']}
                        optionLabel={['description']}
                        value={onlineArticles ?? ''} 
                    />
                </div>
            </>
            }

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save coupon' : 'Update coupon'}
                onClick={() => {submit()}} 
                style={{display: 'block', marginTop: '50px'}}
            ></BTDButton>

        </FormTemplate>
    )

}

CouponForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

CouponForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default CouponForm;