// Packages
import PropTypes from 'prop-types';

const EditCircleIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="EditCircleIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg height={height} width={width} viewBox="0 0 20 20" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group" fill={colour} fillRule="nonzero">
                        <g id="pencil" transform="translate(5, 5)">
                            <path d="M6.18561141,1.66666667 L0.674496919,7.17807797 C0.646770544,7.2058833 0.62675035,7.24099744 0.617216925,7.27873323 L0.00636268045,9.73052674 C-0.0119097289,9.80433 0.00977882973,9.88282046 0.0636426745,9.93668332 C0.104398064,9.97743797 0.159930274,10 0.216733607,10 C0.234132114,10 0.251927841,9.99785503 0.269246888,9.99348562 L2.72108509,9.38256305 C2.75929823,9.37302979 2.79401578,9.3530894 2.82174218,9.32536353 L8.33333333,3.81434946 L6.18561141,1.66666667 Z" id="Path"></path>
                            <path d="M9.67086522,0.957269903 L9.03515272,0.319523564 C8.61027413,-0.106714297 7.86976207,-0.106301362 7.44537746,0.319523564 L6.66666667,1.10072566 L8.89215443,3.33333333 L9.67086522,2.55213124 C9.88309869,2.33930137 10,2.05602374 10,1.75474187 C10,1.45346 9.88309869,1.17018236 9.67086522,0.957269903 Z" id="Path"></path>
                        </g>
                        <path d="M10,20 C4.48593141,20 0,15.5140686 0,10 C0,4.48593141 4.48593141,0 10,0 C15.5140686,0 20,4.48593141 20,10 C20,15.5140686 15.5140686,20 10,20 Z M10,1.42852781 C5.27374266,1.42852781 1.42852781,5.27374266 1.42852781,10 C1.42852781,14.7262573 5.27374266,18.5714722 10,18.5714722 C14.7262573,18.5714722 18.5714722,14.7262573 18.5714722,10 C18.5714722,5.27374266 14.7262573,1.42852781 10,1.42852781 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

EditCircleIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EditCircleIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default EditCircleIcon;