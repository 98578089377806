// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';

const UserForm = ({ errors, item, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    // Load Item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);
		}

	}, [item]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create user' : 'Edit user'}</h3>

            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                tabIndex={1}
                value={data.name ?? ''} 
            />

            <BTDTextField
                error={errors && errors.email ? errors.email : null} 
                label='Email'
                onChange={(value) => {
                    setData({...data, email: value});
                    setChangedData({...changedData, email: value});
                }} 
                required={true}
                tabIndex={2}
                value={data.email ?? ''} 
            />

            <p style={{marginBottom: '20px'}}>{type === 'create' ? 'Set ' : 'Change '}password</p>

            <BTDTextField
                error={errors && errors.password ? errors.password : null} 
                label='New Password'
                onChange={(value) => {setNewPassword(value)}} 
                required={true}
                style={{maxWidth: '400px'}}
                tabIndex={3}
                type='password'
                value={newPassword ?? ''} 
            />

            <BTDTextField
                label='Confirm New Password'
                onChange={(value) => {setConfirmPassword(value)}} 
                required={true}
                style={{marginBottom: '5px', maxWidth: '400px'}}
                tabIndex={4}
                type='password'
                value={confirmPassword ?? ''} 
            />

            {newPassword && newPassword.length !== 0 && confirmPassword && confirmPassword.length !== 0 && newPassword !== confirmPassword &&
                <p className='red'>Passwords must match!</p>
            }
            {newPassword && newPassword.length !== 0 && confirmPassword && confirmPassword.length !== 0 && newPassword === confirmPassword &&
                <p className='green'>Passwords match!</p>
            }
            
            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save user' : 'Update user'}
                onClick={() => {
                    let submitData = {...changedData}
                    if (newPassword !== '' && newPassword !== null) {
                        submitData = {...submitData, password: newPassword}
                    }
                    if (confirmPassword !== '' && confirmPassword !== null) {
                        submitData = {...submitData, password_confirmation: confirmPassword}
                    }
                    onSubmit(submitData)
                }} 
                style={{display: 'block', marginTop: '50px'}}
            ></BTDButton>

        </FormTemplate>
    )

}

UserForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

UserForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default UserForm;