// Packages
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom';

// Components
import BookingForm from 'modules/Bookings/components/Form';

// Other
import useApi from 'hooks/useApi';
import AdminLayout from 'layouts/Admin';

const BookingEditScreen = () => {

    const { orderId } = useParams();

    const { data: item, isLoading, put: updateItem, errors } = useApi('/orders/' + orderId + '?with=bookings,coupon');

	const submit = async (values) => {

        await updateItem(values, 'Booking updated!', '/admin/bookings/' + orderId)
    }

    return(

        <AdminLayout>

            {! isLoading && item &&
                <BookingForm 
                    admin={true}
                    errors={errors} 
                    item={item} 
                    onSubmit={submit} 
                    type='edit' 
                />
            }

        </AdminLayout>
    )
}

BookingEditScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BookingEditScreen.defaultProps = {
    itemId: null,
}

export default BookingEditScreen;