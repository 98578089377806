// Packages
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Context
import { useSnackbar } from 'context/Snackbar';

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import PublicLayout from 'layouts/Public';

// Other
import './styles.css';
import config from 'other/config';

const LoginScreen = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const url = new URL(window.location.href);

    const submit = async () => {

        try {
            const response = await axios.post(config.API_URL + '/sanctum/token', {email: email, password: password});

            if (response.status >= 200 && response.status < 300) {

                localStorage.setItem('access-token', response.data.access_token);

                let redirect = decodeURIComponent(url.searchParams.get('redirect'));

                let redirectUrl = redirect != null && redirect !== 'null' ? redirect : '/admin/bookings';

                navigate(redirectUrl);
            }
            else {

                showSnackbar(response.error, 'error');
            }

        } catch (error) {

            showSnackbar('Login failed', 'error');
        }

    }

    return(
        <PublicLayout>
            <div className='LoginScreen'>

                <div className='LoginScreen__main'>

                    <BTDTextField
                        autocomplete={true}
                        label='Email Address'
                        onChange={(value) => setEmail(value)}
                        style={{backgroundColor: '#fff'}}
                        value={email}
                    />
                    <BTDTextField
                        autocomplete={true}
                        label='Your Password'
                        onChange={(value) => setPassword(value)}
                        type='password'
                        style={{backgroundColor: '#fff'}}
                        value={password}
                    />

                    <BTDButton
                        formButton={true}
                        label='Login >'
                        onClick={() => submit()}
                        style={{marginBottom: '20px'}}

                    />

                    <Link className='light-blue' to='/login/forgot-password'>Forgot your password?</Link>
                </div>
            </div>
        </PublicLayout>

    )

}

export default LoginScreen;