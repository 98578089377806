// Components
import SettingsList from 'modules/Settings/components/List';
import AdminLayout from 'layouts/Admin';

// Other
import useApi from 'hooks/useApi';

const SettingsScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/settings');

    return(
        <AdminLayout>

            <h2 style={{margin: '40px 0'}}>Settings</h2>

            <h4>Email</h4>

            <SettingsList 
                isLoading={isLoading}  
                items={items ? items.Email : []} 
                onChange={() => {onChange()}}
            />

            <h4 style={{marginTop: '50px'}}>GST</h4>

            <SettingsList 
                isLoading={isLoading}  
                items={items ? items.Tax : []} 
                onChange={() => {onChange()}}
            />

            <h4 style={{marginTop: '50px'}}>Magazine Issues</h4>

            <SettingsList 
                isLoading={isLoading}  
                items={items ? items['Magazine Issues'] : []} 
                onChange={() => {onChange()}}
            />


        </AdminLayout>
    )

}

export default SettingsScreen;