// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import LoginScreen from "modules/Login/screens"
import ForgotPasswordScreen from "../screens/ForgotPassword";
import PasswordResetScreen from "../screens/PasswordReset";


const LoginRoutes = () => 
{
	return (
		<Routes>
			<Route path="/reset-password/*" element={<PasswordResetScreen/>}/>
			<Route path="/forgot-password/*" element={<ForgotPasswordScreen/>}/>
			<Route path="/*" element={<LoginScreen />}/>
		</Routes>
	)
}

export default LoginRoutes;