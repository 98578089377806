// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import CouponListScreen from "modules/Coupons/screens/List"


const CouponRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<CouponListScreen />}/>
		</Routes>
	)
}

export default CouponRoutes;