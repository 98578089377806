// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import SettingsEditScreen from 'modules/Settings/screens/Edit';
import EditCircleIcon from 'components/BTDUI/icons/EditCircleIcon';

// Other
import './styles.css'

const SettingsListItem = ({ id, isLoading, onChange, name, value }) => {

	const [formActive, setFormActive] = useState(false);

	return (
		<>
		<div className="SettingsListItem card">

			<p className="SettingsListItem__item SettingsListItem__item__name">{! isLoading ? name : <BTDSkeleton />}</p>
			<p className="SettingsListItem__item">{! isLoading ? value : <BTDSkeleton />}</p>

			<span className="SettingsListItem__icon" onClick={() => {setFormActive(true);}}><EditCircleIcon style={{cursor: 'pointer', transform: 'translateY(1.5px)'}} /></span>

		</div>

		{formActive &&
			<SettingsEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
			/>
		}
		</>
	)
}

SettingsListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.string,
}

SettingsListItem.defaultProps = {
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	value: ''
}

export default SettingsListItem;