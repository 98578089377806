// Packages
import PropTypes from 'prop-types';
import advancedFormat from 'dayjs/plugin/advancedFormat';


// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'
import dayjs from 'dayjs';
import Currency from 'components/BTDUI/Currency';

const BookingListItem = ({ contact_name, created_at, id, isLoading, status, total, trading_name, }) => {

	dayjs.extend(advancedFormat);

	return (
		<>
		<div className="BookingListItem">

			<div className='BookingListItem__date'>
				<p className='blue'>{! isLoading ? '#' + id : <BTDSkeleton />}</p>
				<p className='blue opacity'>{! isLoading ? dayjs(created_at).format('D MMM YYYY') : ''}</p>
			</div>

			<p className='blue'><span className='BookingListItem__label semi-bold'>Name: </span>{! isLoading ? contact_name : <BTDSkeleton />}</p>
			<p className='blue'><span className='BookingListItem__label semi-bold'>Organisation: </span>{! isLoading ? trading_name : <BTDSkeleton />}</p>
			<p className='blue right'>{! isLoading ? <Currency value={total} /> : <BTDSkeleton />}</p>
			<p className={status === 'Approved' ? 'green centre' : 'blue opacity centre'}>{! isLoading ? status : <BTDSkeleton />}</p>

		</div>

		</>
	)
}

BookingListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
}

BookingListItem.defaultProps = {
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
}

export default BookingListItem;