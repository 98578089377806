// Packages
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import AdminLayout from 'layouts/Admin';
import BTDButton from 'components/BTDUI/BTDButton';
import Currency from 'components/BTDUI/Currency';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const BookingViewScreen = () => {

	const { orderId } = useParams();

	dayjs.extend(advancedFormat)

	const { data, isLoading, onChange } = useApi('/orders/' + orderId + '/groupedbookings?with=country,billing_country,coupon');
	const { destroy, put: updateOrder } = useApi('/orders/' + orderId + '?with=country,billing_country,coupon');
	const { data: approvalData, get: sendLink } = useApi('/orders/' + orderId + '/send-approval?with=bookings,country,coupon', false);
	const { data: pdfUrl, get: generatePDF } = useApi('/orders/' + orderId + '/generate-pdf', false);

	const [statusText, setStatusText] = useState('');
	const [statusColour, setStatusColour] = useState('');
	const [order, setOrder] = useState({});
	const [address, setAddress] = useState([])

	const navigate = useNavigate();

	useEffect(() => {

		if( data && Object.keys(data).length > 0 ) {

			setOrder(data)
		}
	}, [data])

	useEffect(() => {

		if( order && order.billing_address ) {

			setAddress(order.billing_address.split('\n'))
		}
	}, [order])
	
	useEffect(() => {

		if ( approvalData && Object.keys(approvalData).length > 0 ) {

			setOrder(approvalData)
		}
	}, [approvalData])

	const deleteOrder = async () => {

		if( await destroy('booking', 'Booking Deleted!') ) {
			
			navigate('/admin/bookings');
		}
	}

	useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])


	useEffect(() => {

		if( order ) {

			switch (order.status) {
				case 'Pending':
					setStatusColour('orange');
					setStatusText('Waiting for client approval');
					break;
				case 'Approved':
					setStatusColour('green');
					setStatusText('Approved by client');
					break;
				default:
					setStatusColour('grey');
					setStatusText('Draft');
					break;
			}
		}
	}, [order])

	return (
		<AdminLayout>
			<div className='BookingViewScreen'>

				<h2>View Booking #{! isLoading ? order.id : <BTDSkeleton width='50px'/>}</h2>

				<div className='BookingViewScreen__buttons'>
					<BTDButton
						label='Download PDF'
						onClick={() => {generatePDF()}}
						size='small'
					/>
					<BTDButton
						label='Edit'
						url={'/admin/bookings/' + order.id + '/edit'}
						size='small'
					/>
				</div>
				<a id="downloadLink" href="" target='_blank'/>

				<div className={`BookingViewScreen__status ${statusColour}`}>
					<h4 className={`BookingViewScreen__status__text`}>{statusText}</h4>
					{order.link_id && order.status !== 'Approved' &&
						<Link className='light-blue' to={'/approve/' + order.link_id} target='_blank'>View client approval form</Link>
					}
					{order && order.status === 'Draft' &&
						<BTDButton
							colour='red'
							formButton={true}
							label='Send Approval Email'
							onClick={() => {
								if(window.confirm('Are you sure you want to send an approval email to the client?')) {
									sendLink();
								}
							}}
							size='small'
						/>
					}
					{order && order.status === 'Pending' &&
						<BTDButton
							colour='orange'
							formButton={true}
							label='Resend Approval Email'
							onClick={() => {
								if(window.confirm('Are you sure you want to send an approval email to the client?')) {
									sendLink();
								}
							}}
							size='small'
						/>
					}
					{order && order.status === 'Approved' &&
						<BTDButton
							colour='red'
							formButton={true}
							label='Revert to Draft'
							onClick={async () => {
								if(window.confirm('Are you sure you want to change this booking back to a draft?')) {
									if( await updateOrder({status: 'Draft'}) ) {
										onChange();
									};
								}
							}}
							size='small'
							type='outlined'
						/>
					}
				</div>
			
			</div>

			<div className="gridTable">
				<div className="gridTable__heading gridTable__heading-top">Trading Name</div>
				<div className="gridTable__data gridTable__data-top">{order.trading_name}</div>
				<div className="gridTable__heading">Billing Name</div>
				<div className="gridTable__data">{order.billing_name}</div>
				<div className="gridTable__heading">Billing Address</div>
				<div className="gridTable__data">{address && address.map((line) => (<p>{line}</p>))}</div>
				<div className="gridTable__heading">Post Code</div>
				<div className="gridTable__data">{order.postcode}</div>
				<div className="gridTable__heading">Country</div>
				<div className="gridTable__data">{order.country ? order.country.name : ''}</div>
				<div className="gridTable__heading">Billing Country</div>
				<div className="gridTable__data">{order.billing_country ? order.billing_country.name : ''}</div>
				<div className="gridTable__heading">Contact Name</div>
				<div className="gridTable__data">{order.contact_name}</div>
				<div className="gridTable__heading">Title / Position</div>
				<div className="gridTable__data">{order.title_position}</div>
				<div className="gridTable__heading">Telephone</div>
				<div className="gridTable__data">{order.telephone}</div>
				<div className="gridTable__heading">Email</div>
				<div className="gridTable__data"><Link to={'mailto:' + order.email}>{order.email}</Link></div>
				<div className="gridTable__heading">Billing Contact</div>
				<div className="gridTable__data"><Link to={'mailto:' + order.billing_email}>{order.billing_email}</Link></div>
				{order && order.bookings && order.bookings['Magazine'] &&
					<>
					<div className="gridTable__heading">Magazine Ads</div>
					<div className="gridTable__data">
					{order.bookings['Magazine'].map((ad, index) => {
						return(

							<div className="gridTable__data__adDiv" key={index}>
								<span className="gridTable__data__adDiv__item">{ad.description}</span>
								<span className="gridTable__data__adDiv__item"><Currency value={ad.rate} /></span>
								<span className="gridTable__data__adDiv__item">{ad.issue}</span>
								<span></span>
								<span className="gridTable__data__adDiv__item">total: <b><Currency value={ad.rate} /></b></span>
							</div>
						)
					})}
					</div>
					</>
					}
				{order && order.bookings && order.bookings['Website'] &&
					<>
					<div className="gridTable__heading">Website Ads</div>
					<div className="gridTable__data">
					{order.bookings['Website'].map((ad, index) => {
						return(

						<div className="gridTable__data__adDiv" key={index}>
							<span className="gridTable__data__adDiv__item">{ad.description}</span>
							<span className="gridTable__data__adDiv__item"><Currency value={ad.rate} /></span>
							<span className="gridTable__data__adDiv__item">date: {ad.date}</span>
							<span className="gridTable__data__adDiv__item">months: {ad.quantity}</span>
							<span className="gridTable__data__adDiv__item">total: <b><Currency value={ad.rate * ad.quantity} /></b></span>
						</div>
						)
					})}
					</div>
					</>
				}
				{order && order.bookings && order.bookings['EDM'] &&
					<>
					<div className="gridTable__heading">EDM Ads</div>
					<div className="gridTable__data">
					{order.bookings['EDM'].map((ad, index) => {
						return(
						<div className="gridTable__data__adDiv" key={index}>
							<span className="gridTable__data__adDiv__item">{ad.description}</span>
							<span className="gridTable__data__adDiv__item"><Currency value={ad.rate} /></span>
							<span className="gridTable__data__adDiv__item">date: {ad.date}</span>
							<span className="gridTable__data__adDiv__item">quantity: {ad.quantity}</span>
							<span className="gridTable__data__adDiv__item">total: <b><Currency value={ad.rate * ad.quantity} /></b></span>
						</div>
						)
					})}
					</div>
					</>
				}
				{order && order.bookings && order.bookings['Online Articles'] &&
					<>
					<div className="gridTable__heading">Online Articles</div>
					<div className="gridTable__data">
					{order.bookings['Online Articles'].map((ad, index) => {
						return(
						<div className="gridTable__data__adDiv" key={index}>
							<span className="gridTable__data__adDiv__item">{ad.description}</span>
							<span className="gridTable__data__adDiv__item"><Currency value={ad.rate} /></span>
							<span className="gridTable__data__adDiv__item">date: {ad.date}</span>
							<span className="gridTable__data__adDiv__item">quantity: {ad.quantity}</span>
							<span className="gridTable__data__adDiv__item">total:  <b><Currency value={ad.rate * ad.quantity} /></b></span>
						</div>
						)
					})}
					</div>
					</>
				}
				
				<div className="gridTable__heading">Subtotal</div>
				<div className="gridTable__data"><Currency value={order.subtotal} /></div>

				{order.coupon &&
					<>
						<div className="gridTable__heading">Discount</div>
						<div className="gridTable__data"><Currency value={order.discount} />{' (' + order.coupon.code + ')'}</div>
					</>
				}
				{order.billing_country_id == 199 &&
				<>
					<div className="gridTable__heading">GST ({order.gst_rate}%)</div>
					<div className="gridTable__data"><Currency value={((order.subtotal - order.discount) / 100) * order.gst_rate} /></div>
				</>
				}
				<div className="gridTable__heading bold">Total</div>
				<div className="gridTable__data bold"><Currency value={order.total} /></div>
				<div className="gridTable__heading">Additional Comments</div>
				<div className="gridTable__data">{order.additional_comments}</div>
				<div className="gridTable__heading">Conditions Agreement</div>
				<div className="gridTable__data">{order.agreed_conditions ? 'Accepted' : 'No' }</div>
				<div className="gridTable__heading">Notify Changes</div>
				<div className="gridTable__data">{order.notify_changes ? 'Accepted' : 'No' }</div>
				<div className="gridTable__heading">Signature</div>
				{order.signature && order.signature.length > 0 ?
					<img className="gridTable__data gridTable__data-signature" src={order.signature} alt='Signature' />
					:
					<div></div>
				}
				<div className="gridTable__heading">Date</div>
				<div className="gridTable__data">{order ? dayjs(order.created_at).format('Do MMMM YYYY') : ''}</div>
			</div>

			<BTDButton
				colour='red'
				label='Delete'
				onClick={() => {deleteOrder()}}
				size='small'
				style={{margin: '20px 0 0 auto'}}
			/>

		</AdminLayout>
	)

}

export default BookingViewScreen;