// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';


const DashboardForm = ({ errors, item, onClose, onSubmit }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [options, setOptions] = useState([])
    

    // Load Item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);

            if ( item.input_type === 'select' ) {

                let optionsArray = item.options.split(',')

                var options = optionsArray.map((option) => ({ id: option, name: option }));

                setOptions(options);
            }
		}

	}, [item]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium" style={{marginBottom: '60px'}}>Edit setting</h3>

            {item.input_type === 'text' &&
            
                <BTDTextField 
                    error={errors && errors.value ? errors.value : null} 
                    label={item.name}
                    onChange={(value) => {
                        setData({...data, value: value});
                        setChangedData({...changedData, value: value});
                    }} 
                    required={true}
                    value={data.value ?? ''} 
                />
            }

            {item.input_type === 'number' &&
            
                <BTDNumberField 
                    error={errors && errors.value ? errors.value : null} 
                    label={item.name}
                    onChange={(value) => {
                        setData({...data, value: value});
                        setChangedData({...changedData, value: value});
                    }} 
                    required={true}
                    value={data.value ?? ''} 
                />
            }

            {item.input_type === 'textarea' &&
            
                <BTDTextareaField
                    error={errors && errors.value ? errors.value : null} 
                    label={item.name}
                    onChange={(value) => {
                        setData({...data, value: value});
                        setChangedData({...changedData, value: value});
                    }} 
                    required={true}
                    rows={8}
                    value={data.value ?? ''} 
                />
            }

            {item.input_type === 'select' &&
            
                <BTDSelectField
                    blankOption={false}
                    error={errors && errors.value ? errors.value : null} 
                    label={item.name}
                    onChange={(value) => {
                        setData({...data, value: value});
                        setChangedData({...changedData, value: value});
                    }} 
                    options={options}
                    required={true}
                    value={data.value ?? ''} 
                />
            }
        

            <BTDButton 
                formButton={true}
                label='Save setting'
                onClick={() => {onSubmit(changedData)}} 
                style={{marginTop: '80px'}}
            ></BTDButton>

        </FormTemplate>
    )

}

DashboardForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

DashboardForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
}

export default DashboardForm;