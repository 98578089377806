// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import PlacementsListScreen from "modules/Placements/screens/List"


const PlacementRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<PlacementsListScreen />}/>
		</Routes>
	)
}

export default PlacementRoutes;