// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';

// Other
import logo from 'assets/anza-logo.png';
import './styles.css';
import Footer from 'components/Footer';

const PublicLayout = ({children}) => {

    return(
        <div className="PublicLayout">

            <header className="PublicLayout__header">

                <div className='PublicLayout__header__container'>

                    <Container>

                    <div className="PublicLayout__header__logo">
                        <img src={logo} alt="Logo for Anza"/>
                    </div>
                    
                    <div className="PublicLayout__header__title">ANZA Advertising</div>

                    </Container>

                </div>

        </header>

        <div className='PublicLayout__container'>

            <Container>

                {children}

            </Container>
        </div>

        <Footer/>
        

        </div>
    )
}

PublicLayout.propTypes = {
    children: PropTypes.node
}

PublicLayout.defaultProps = {
    children: <></>
}

export default PublicLayout;