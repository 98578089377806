// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDNumberField = ({ className, description, error, height, label, onChange, required, step, style, value, width }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if( value != null && value !== '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <div>
        {description &&
            <p className='BTDNumberField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDNumberField ${className} BTDNumberField--height-${height} ${width} `} style={style}>
            <label className={`BTDNumberField__label ${active ? 'active' : ''}`}>
                <span className='BTDNumberField__label__wrapper'>
                    {label ?? ''}
                    {required &&
                        <span className='red'>{<>&nbsp;</>}*</span>
                    }
                </span>
            </label>
            <input 
                className={`BTDNumberField__input ${error ?? ''} BTDNumberField__input--height-${height}`} 
                onChange={(e) => {onChange(e.target.value)}} 
                required={required} 
                step={step ?? 1}
                type='number' 
                value={value} 
            />
        </div>
        {error &&
            <p className='BTDNumberField__error'>{error}</p>
        }
        </div>
    )
}

BTDNumberField.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    height: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    step: PropTypes.number,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string
}

BTDNumberField.defaultProps = {
    className: '',
    description: null,
    error: null,
    height: 'normal',
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    step: 1,
    style: {},
    value: null,
    width: 'small'
}

export default BTDNumberField;                                         