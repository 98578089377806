// Packages
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// Components
import BookingForm from 'modules/Bookings/components/Form';
import AdminLayout from 'layouts/Admin';
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const BookingCreateScreen = () => {

    const [query, setQuery] = useState('');
    const [item, setItem] = useState({});
    const [searchBoxOpen, setSearchBoxOpen] = useState(false);

    const { post: createItem, errors } = useApi('/orders',  false);
    const { data: searchItems, isLoading: searchLoading, get: search } = useApi('/orders/search?query=' + query, false);

    useEffect(() => {

        if(searchItems && searchItems.length > 0) {

            setSearchBoxOpen(true);
        }

        else if(query === '') {

            setSearchBoxOpen(false);
        }

    }, [searchItems])

	const submit = async (values) => {

        await createItem(values, 'Booking created!', '/admin/bookings/')
    }

    return(

        <AdminLayout>

            <div className='BookingCreateScreen__search'>
                <form 
                    className='BookingCreateScreen__search__form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        search();
                    }}
                >
                    <span style={{display: 'inline-block', padding: '8px 0'}}>Search for customer (hit enter):{<>&nbsp;&nbsp;&nbsp;</>}</span>
                    <input 
                        className='BookingCreateScreen__search__form__input' 
                        onChange={(e) => {setQuery(e.target.value)}} 
                        style={{border: '1px solid #E6E9EF', marginBottom: '10px', outline: '0', padding: '10px', maxWidth: '400px', width: '100%'}}
                        value={query ?? ''}
                    />
                    {searchLoading &&
                        <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', height: '150px', maxWidth: '569px', width: '100%'}}>
                            <BTDProgress height={25} type='circular' width={25}/>
                        </div>
                    }
                    {searchBoxOpen &&
                        <div className='BookingCreateScreen__search__list'>
                            {searchItems.length > 0 ? searchItems.map((item, index) => (
                                <p 
                                    className='BookingCreateScreen__search__list__item' 
                                    key={index}
                                    onClick={() => {
                                        setItem({...item, id: null, bookings: [], coupon_id: null, additional_comments: null, status: 'Draft', agreed_conditions: false, notify_changes: false, link_id: null, signature: null, subtotal: 0, total: 0, discount: 0});
                                        setQuery('');
                                        setSearchBoxOpen(false);
                                    }}
                                >
                                    {item.trading_name + ' (' + item.contact_name + ') [' + dayjs(item.created_at).format('D MMM YYYY') + ']'}
                                </p>
                                )
                            )
                            :
                            <p className='italic'>No results</p>
                            }
                        </div>
                    }
                </form>

            </div>

            <BookingForm 
                admin={true}
                errors={errors} 
                item={item}
                onSubmit={submit} 
                type='create' 
            />

        </AdminLayout>

    )
}

export default BookingCreateScreen;
