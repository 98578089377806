// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDTextareaField = ({ className, description, error, label, onChange, required, rows, style, value, width }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if( value != null && value !== '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <>
        {description &&
            <p className='BTDTextareaField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={className !== '' ? className : `BTDTextareaField ${width}`} style={style}>
            <label className={className !== '' ? (className + '__label') : `BTDTextareaField__label ${active ? 'active' : ''}`}>{label ?? ''}
                {required &&
                    <span className='red'>{<>&nbsp;</>}*</span>
                }
            </label>
            <textarea 
                className={className !== '' ? (className + '__input') : `BTDTextareaField__input ${error ?? ''}`} 
                onChange={(e) => {onChange(e.target.value)}} 
                required={required} 
                rows={rows}
                value={value}
            />
        </div>
        {error &&
            <p className='BTDTextareaField__error'>{error}</p>
        }
        </>
    )
}

BTDTextareaField.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    rows: PropTypes.number,
    style: PropTypes.object,
    value: PropTypes.string,
    width: PropTypes.string
}

BTDTextareaField.defaultProps = {
    className: '',
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    rows: 6,
    style: {},
    value: null,
    width: 'large'
}

export default BTDTextareaField;                                         