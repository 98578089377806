// Packages
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDRadioField = ({ description, error, label, onChange, options, required, value }) => {

    return(
        <>
        <p className='form__label black medium opacity'>{label}
            {required &&
                <span className='red'> *</span>
            }
        </p>
        {description &&
            <p className='BTDRadioField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDRadioField`}>
            {options.map((option, index) => (
            <label key={index} className="BTDRadioField__label">
                <input
                    checked={value === option.value}
                    className="BTDRadioField__input"
                    onChange={() => onChange(option.value)}
                    required={required}
                    type="radio"
                    value={option.value}
                    />
                <span className={`BTDCheckboxField__button ${option.colour}`}></span>
                {option.label}
            </label>
            ))}
            {error &&
                <p className='BTDRadioField__error'>{error}</p>
            }
        </div>
        </>
    )
}

BTDRadioField.propTypes = {
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

BTDRadioField.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    options: [],
    required: false,
    value: {}
}

export default BTDRadioField;                                         