// Packages
import PropTypes from 'prop-types'

// Components
import PlacementForm from 'modules/Placements/components/Form';

// Other
import useApi from 'hooks/useApi';

const PlacementEditScreen = ({  itemId, onClose, onSubmit }) => {

    const { data: item, put: updateItem, errors } = useApi('/placements/' + itemId);

	const submit = async (values) => {

        if( await updateItem(values, 'Placement updated!')) {

            onSubmit();
        }
    }

    return(
        <PlacementForm 
            errors={errors} 
            item={item} 
            onClose={onClose} 
            onSubmit={submit} 
            type='edit' 
        />
    )
}

PlacementEditScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

PlacementEditScreen.defaultProps = {
    itemId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default PlacementEditScreen;