// Packages
import { useState } from 'react';

// Components
import PlacementList from 'modules/Placements/components/List';
import AdminLayout from 'layouts/Admin';
import PlacementCreateScreen from '../Create';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import useApi from 'hooks/useApi';
import './styles.css'

const PlacementsScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/placements');

    const [formActive, setFormActive] = useState(false);

    return(
        <AdminLayout>

            <h2 style={{margin: '40px 0'}}>Placements</h2>

            <h4>Magazine</h4>

            <BTDButton
                label='Add Placement'
                onClick={() => {setFormActive(true)}}
                size='small'
                style={{margin: '0 0 10px auto'}}
            />

            <PlacementList 
                isLoading={isLoading}  
                items={items ? items.Magazine : []} 
                onChange={() => {onChange()}}
            />

            <h4 style={{marginTop: '50px'}}>Website</h4>

            <PlacementList 
                isLoading={isLoading}  
                items={items ? items.Website : []} 
                onChange={() => {onChange()}}
            />

            <h4 style={{marginTop: '50px'}}>Online Articles</h4>

            <PlacementList 
                isLoading={isLoading}  
                items={items ? items['Online Articles'] : []} 
                onChange={() => {onChange()}}
            />

            <h4 style={{marginTop: '50px'}}>EDM (Weekly E-Newsletter)</h4>

            <PlacementList 
                isLoading={isLoading}  
                items={items ? items.EDM : []} 
                onChange={() => {onChange()}}
            />

            {formActive &&
                <PlacementCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </AdminLayout>
    )

}

export default PlacementsScreen;