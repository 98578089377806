// Packages
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

// Other
import './styles.css';

const BTDMultiselect = ({className, description, error, label, onChange, options, optionLabel, required, style, value, width, }) => {

    const [active, setActive] = useState(false);
    const [selectedValues, setSelectedValues] = useState(value || []);
    const [open, setOpen] = useState(false);
    const node = useRef();

    useEffect(() => {

        setSelectedValues(value);

    }, [value])

    useEffect(() => {

        if (selectedValues.length > 0) {

            setActive(true);

        } else {

            setActive(false);
        }

    }, [selectedValues]);

    const handleClick = (optionId) => {

    let newSelectedValues = [...selectedValues];

        if (newSelectedValues.includes(optionId)) {

            newSelectedValues = newSelectedValues.filter((value) => value !== optionId);

        } else {

            newSelectedValues.push(optionId);
        }

        setSelectedValues(newSelectedValues);
        onChange(newSelectedValues);
    };

    const handleClickOutside = (e) => {

        if (node.current.contains(e.target)) {

            return;
        }
        setOpen(false);
    };

    useEffect(() => {
    if (open) {
        document.addEventListener('mousedown', handleClickOutside);
    } else {
        document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [open]);

    const getNestedPropertyValue = (obj, path) => {
        const properties = path.split('.');
        return properties.reduce((acc, property) => acc && acc[property], obj);
    };

    return (
        <div>
        {description &&
            <p className='BTDMultiselect__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
    
            <div ref={node} className={`BTDMultiselect ${className} ${width}`} style={style}>

                <div className={`BTDMultiselect__select ${active ? 'active' : ''}`} onClick={() => {setOpen(! open)}}>

                    {options.length > 0 && options.map((option) => {
                        if( selectedValues.includes(option.id)) {

                            let text = '';

                            if( optionLabel ) {

                                for( let i = 0; i < optionLabel.length; i++ ) {

                                    if (optionLabel[i].includes('.')) {
                                        
                                        text += getNestedPropertyValue(option, optionLabel[i]) + ' ';
                                    }
                                    else {

                                        text += option[optionLabel[i]] + ' ';
                                    }
                                }
                            }
                            else {
                                text = option.name;
                            }
                            return(
                                <span className='BTDMultiselect__select__tab' key={value.id}>
                                    {text} 
                                    <ClearIcon 
                                        colour='#aaa' 
                                        height='16px' 
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            handleClick(option.id);
                                        }} 
                                        style={{cursor: 'pointer', marginLeft: '5px', transform: 'translateY(1px)'}} 
                                        width='16px'
                                    />
                                </span>
                            )
                        }
                        else {
                            return(<></>)
                        }
                    })}

                    <span className={`BTDMultiselect__select__downArrow ${open ? 'open' : ''}`}></span>

                    <div className={`BTDMultiselect__label ${active ? 'active' : ''}`}>
                        <span className='BTDMultiselect__label__wrapper'>
                            {label ?? ''}
                            {required && <span className="red">{<>&nbsp;</>}*</span>}
                        </span>
                    </div>

                </div>

                {open && (
                <div className="BTDMultiselect__dropdown">
                    {options.map((option, index) => {
                        
                        let text = '';

                        if( optionLabel ) {

                            for( let i = 0; i < optionLabel.length; i++ ) {

                                if (optionLabel[i].includes('.')) {
                                        
                                    text += getNestedPropertyValue(option, optionLabel[i]) + ' ';
                                }
                                else {

                                    text += option[optionLabel[i]] + ' ';
                                }
                            }
                        }
                        else {
                            text = option.name;
                        }

                        return(
                    <div
                        key={index}
                        className="BTDMultiselect__dropdown__option"
                        onClick={() => handleClick(option.id)}
                    >
                        {text}
                        {selectedValues.includes(option.id) ? <span> &#10003;</span> : null}
                    </div>
                    )})}
                </div>
                )}
            </div>
                {error && <p className="BTDMultiselect__error">{error}</p>}
        </div>
    );
};

BTDMultiselect.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    optionLabel: PropTypes.array,
    options: PropTypes.array,
    required: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    width: PropTypes.string,
};

BTDMultiselect.defaultProps = {
    className: '',
    description: null,
    error: null,
    label: '',
    onChange: () => {
    alert('onChange is not set!');
    },
    optionLabel: null,
    options: [],
    required: false,
    style: {},
    value: [],
    width: 'large',
};

export default BTDMultiselect;
