// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import StatsScreen from "../screens";


const StatsRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<StatsScreen />}/>
		</Routes>
	)
}

export default StatsRoutes;