// Packages
import { useState } from 'react';

// Components
import AdminLayout from 'layouts/Admin';
import UserCreateScreen from '../Create';
import BTDButton from 'components/BTDUI/BTDButton';
import UserList from 'modules/Users/components/List';

// Other
import useApi from 'hooks/useApi';

const UserScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/users');

    const [formActive, setFormActive] = useState(false);

    return(
        <AdminLayout>

            <h2 style={{margin: '40px 0'}}>Users</h2>

            <BTDButton
                label='Add User'
                onClick={() => {setFormActive(true)}}
                size='small'
                style={{margin: '0 0 15px auto'}}
            />

            <UserList
                isLoading={isLoading}  
                items={items ?? []} 
                onChange={() => {onChange()}}
            />

            {formActive &&
                <UserCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </AdminLayout>
    )

}

export default UserScreen;