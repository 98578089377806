// Packages
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDCheckboxField = ({ checked, className, description, error, label, onChange, required }) => {

    return(
        <>
        {description &&
            <p className='BTDCheckboxField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDCheckboxField ${className}`}>
            <label className='BTDCheckboxField__label'> 
                {label ?? ''} 
                <input 
                    className={`BTDCheckboxField__input .checkbox-label ${error ?? ''}`} 
                    onChange={(e) => {onChange(e.target.checked)}} 
                    required={required} 
                    type="checkbox" 
                    checked={checked} 
                />
                <span className="BTDCheckboxField__checkmark"></span>
            </label>

            {error &&
                <p className='BTDCheckboxField__error red'>{error}</p>
            }
        </div>
        </>
    )
}

BTDCheckboxField.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
}

BTDCheckboxField.defaultProps = {
    checked: false,
    className: '',
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
}

export default BTDCheckboxField;                                         