const Currency = ({value}) => {

    if( value ) {

        var result = value.toLocaleString('en-GB', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }
    
    return result ? 'SG$ ' + result : ''; 
}

export default Currency;