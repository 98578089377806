// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDSwitch = ({ checked, description, error, label, onChange, required, style }) => {

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {

        setIsChecked(checked);

    }, [checked]);

    return(
        <div  className={`BTDSwitch`} style={style}>
            {description &&
                <p className='BTDSwitch__description opacity' dangerouslySetInnerHTML={{__html: description}}></p>
            }
            <div>
                <label className={`BTDSwitch__wrapper ${isChecked ? 'checked' : ''}`}>
                    <input 
                        className={`BTDSwitch__input ${error ?? ''}`} 
                        onChange={(e) => {
                            setIsChecked(e.target.checked);
                            onChange(e.target.checked);
                        }} 
                        required={required} 
                        type="checkbox" 
                        checked={checked} 
                    />
                    <span className="BTDSwitch__slider"></span>
                </label>
            </div>
            <p className='BTDSwitch__label black medium opacity'>{label}
                {required &&
                    <span className='red'> *</span>
                }
            </p>
            {error &&
                <p className='BTDSwitch__error red'>{error}</p>
            }
        </div>
    )
}

BTDSwitch.propTypes = {
    checked: PropTypes.bool,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.object,
}

BTDSwitch.defaultProps = {
    checked: false,
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    style: {}
}

export default BTDSwitch;                                         