// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';


const PlacementForm = ({ errors, item, media, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load Item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);
		}

	}, [item]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create placement' : 'Edit placement'}</h3>

            {type === 'create' && 
            
                <BTDSelectField
                    error={errors && errors.media ? errors.media : null} 
                    label='Media'
                    onChange={(value) => {
                        setData({...data, media: value});
                        setChangedData({...changedData, media: value});
                    }} 
                    options={[
                        {id: 'Magazine', name: 'Magazine'},
                        {id: 'Website', name: 'Website'},
                        {id: 'Online Articles', name: 'Online Articles'},
                        {id: 'EDM', name: 'EDM'},
                    ]}
                    required={true}
                    value={data.media ?? ''} 
                />
            }

            <BTDTextField
                error={errors && errors.description ? errors.description : null} 
                label='Description'
                onChange={(value) => {
                    setData({...data, description: value});
                    setChangedData({...changedData, description: value});
                }} 
                required={true}
                value={data.description ?? ''} 
            />
        

            <BTDNumberField
                error={errors && errors.rate ? errors.rate : null} 
                label='Rate'
                onChange={(value) => {
                    setData({...data, rate: value});
                    setChangedData({...changedData, rate: value});
                }} 
                required={true}
                step={0.01}
                value={data.rate ?? ''} 
            />

            <BTDNumberField
                error={errors && errors.sequence ? errors.sequence : null} 
                label='Sequence'
                onChange={(value) => {
                    setData({...data, sequence: value});
                    setChangedData({...changedData, sequence: value});
                }} 
                step={1}
                value={data.sequence ?? ''} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save placement' : 'Update placement'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

PlacementForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

PlacementForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default PlacementForm;