// Components
import AdminLayout from 'layouts/Admin';
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';
import useApi from 'hooks/useApi';
import Currency from 'components/BTDUI/Currency';

const StatsScreen = () => {

    const { data: stats, isLoading } = useApi('/orders/stats');

    return(
        <AdminLayout>

            {isLoading &&
                <div style={{alignItems: 'center', display: 'flex', height: '300px', justifyContent: 'center', width: '100%'}}>
                    <BTDProgress
                        type='circular'
                        
                        width={75}
                    />
                </div>
            }

            <h1 style={{margin: '40px 0'}}>Stats</h1>

            <p style={{marginBottom: '20px'}}>Number of bookings: {stats.num_bookings}</p>

            <p style={{marginBottom: '100px'}}>Total revenue: <Currency value={stats.revenue}/></p>

        </AdminLayout>

    )

}

export default StatsScreen;