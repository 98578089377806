// Packages
import ListItem from 'modules/Bookings/components/ListItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Other
import './styles.css';

const BookingsList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='BookingsList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No bookings to show here</h3>
		)
	}


	// Data State
	return (
		<div className='BookingsList'>
			<div className="tr-header">
				<span className="th">Booking # / Date</span>
				<span className="th">Name</span>
				<span className="th">Organisation</span>
				<span className="th right">Total</span>
				<span className="th centre">Status</span>
			</div>

			{items.map((item, index) => {

				return (
						<Link key={index} to={'/admin/bookings/' + item.id}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}
	
		</div>
	)
}

BookingsList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

BookingsList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default BookingsList;