// Packages

// Components
import CalendarList from 'modules/Calendar/components/List';
import AdminLayout from 'layouts/Admin';

// Other
import useApi from 'hooks/useApi';
import './styles.css'
import BTDProgress from 'components/BTDUI/BTDProgress';

const CalendarScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/bookings/calendar?with=order');

    return(
        <AdminLayout>

            <h2 style={{marginTop: '35px'}}>Magazine Calendar</h2>
            
            {isLoading &&
                <div style={{alignItems: 'center', display: 'flex', height: '300px', justifyContent: 'center', width: '100%'}}>
                    <BTDProgress
                        type='circular'
                        
                        width={75}
                    />
                </div>
            }

            {items && Object.keys(items).map((issue) => {

                return(
                    <>
                        <h4 style={{marginBottom: '20px'}}>{issue}</h4>


                        <CalendarList 
                            isLoading={isLoading}  
                            items={items[issue]} 
                            onChange={() => {onChange()}}
                        />

                    </>
                )
            })}
            

        </AdminLayout>
    )

}

export default CalendarScreen;