// Packages
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/BTDUI/BTDProgress';
import Config from 'other/config';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        const getUser = async () => 
        {
            if ( localStorage.getItem('access-token') )
            {
                try 
                {
                    const response = await axios.get(Config.API_URL + '/users/current', {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access-token')
                        }
                    })
                        
                    setUser(response.data.data);
                } 
                catch (error) 
                {
                    localStorage.removeItem('access-token');
                    redirectToLogin();
                }
                finally
                {
                    setIsLoading(false);
                }
            }
            else
            {
                redirectToLogin();
            }
        }

        getUser();

    }, [])


    const redirectToLogin = () => {

        if ( window.location.pathname !== '/login' )
        {
            navigate('/login?redirect=' + window.location.pathname);
        }
    }

    return(

        <UserContext.Provider value ={{
            user
        }}>

            { isLoading &&
                <LoadingSpinner type="circular" style={{
                    margin: '100px auto'
                }}/>
            }

            { user !== false &&
                <>{children}</>
            }

        </UserContext.Provider>
    )
}

export const useUser = () => {
    return useContext(UserContext);
};