// Packages
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';
import BTDProgress from 'components/BTDUI/BTDProgress';

const BTDSelectField = ({ blankOption, className, description, error, height, label, onChange, options, optionLabel, required, startIcon, style, value, width }) => {

    const [active, setActive] = useState(false);
    const [displayValue, setDisplayValue] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const dropdownRef = useRef(null);

    useEffect(() => {

        if (value != null && value !== '') {

            setActive(true);
        } else {

            setActive(false);
        }

    }, [value]);

    useEffect(() => {

        if( value != null && value != '' ) {

            setLoading(true);

            for( let i = 0; i < options.length; i++ ) {

                if( options[i].id === value ) {

                    setSelectedIndex(options[i].id);

                    let text = '';
                    if (optionLabel) {
                        for (let j = 0; j < optionLabel.length; j++) {
                            if (optionLabel[j].includes('.')) {
                                text += getNestedPropertyValue(options[i], optionLabel[j]) + ' ';
                            } else {
                                text += options[i][optionLabel[j]] + ' ';
                            }
                        }
                    } else {
                        text = options[i].name;
                    }

                    setDisplayValue(text);
                    break;
                }
            }

            setLoading(false)

        } else {

            setDisplayValue('');
        }
        // eslint-disable-next-line
    }, [options, value])

    const getNestedPropertyValue = (obj, path) => {
        const properties = path.split('.');
        return properties.reduce((acc, property) => acc && acc[property], obj);
    };

    const handleOptionClick = (optionId) => {
        onChange(optionId);
        const newIndex = options.findIndex((option) => option.id === optionId);
        setSelectedIndex(newIndex);
        
        // handle scroll position for re-opening dropdown
        const optionHeight = dropdownRef.current.firstChild.clientHeight;
        const scrollTop = Math.max(0, newIndex * optionHeight - optionHeight * 2);
        dropdownRef.current.scrollTop = scrollTop;
        setOpen(false);
    };

    // const handleKeyDown = (e) => {

    //     if (['ArrowUp', 'ArrowDown'].includes(e.key)) {

    //         e.preventDefault();
    //         const direction = e.key === 'ArrowUp' ? -1 : 1;
    //         const newIndex = (selectedIndex + direction + options.length) % options.length;
    //         setSelectedIndex((prevIndex) => (prevIndex + direction + options.length) % options.length);
    //         const optionHeight = dropdownRef.current.firstChild.clientHeight;
    //         const scrollTop = Math.max(0, newIndex * optionHeight - optionHeight * 2);
    //         dropdownRef.current.scrollTop = scrollTop;

    //     } else if (e.key === 'Enter' && open) {

    //         // Handle Enter key to select the option
    //         handleOptionClick(options[selectedIndex].id);
    //     }
    // };

    useEffect(() => {

        // Scroll to the selected index when the dropdown is opened
        if (open && dropdownRef.current) {
            const optionHeight = dropdownRef.current.firstChild.clientHeight;
            const scrollTop = Math.max(0, selectedIndex * optionHeight - optionHeight * 2);
            dropdownRef.current.scrollTop = scrollTop;
        }
    }, [open, selectedIndex]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {description && (
                <p className='BTDSelectField__description detail' dangerouslySetInnerHTML={{ __html: description }}></p>
            )}
            <div className={className !== '' ? className : `BTDSelectField BTDSelectField--height-${height} ${width} `} onClick={() => {setOpen(! open)}} style={style} ref={dropdownRef}>

                <label className={className !== '' ? (className + '__label') : `BTDSelectField__label ${active ? 'active' : ''} ${startIcon ? 'startIcon' : ''}`}>
                    <span className='BTDSelectField__label__wrapper'>
                        {label ?? ''}
                        {required && <span className='red'>{<>&nbsp;</>}*</span>}
                    </span>
                </label>

                {className !== '' && <span className={className + '__input'} style={{padding: '11.5px'}}>{<>&nbsp;</>}</span>}

                {startIcon && (
                    <div className='BTDSelectField__startIcon'>
                        {startIcon}
                    </div>
                )}

                {loading ?
                    <BTDProgress height={25} style={{display: 'block', margin: '0px', padding: '10px 0 0 18px'}} type='circular' width={25} />
                :
                    <span className={`BTDSelectField__downArrow ${active ? 'active' : ''}`}></span>
                }

                {displayValue && displayValue !== '' &&
                    <span className={`BTDSelectField__displayValue ${startIcon ? 'startIcon' : ''}`}>{displayValue}</span>
                }

                <div className={`BTDSelectField__dropdown ${open ? 'open' : ''}`}>

                    {blankOption && (
                        <div className='BTDSelectField__option' onClick={() => handleOptionClick(null)}>{<>&nbsp;</>}</div>
                    )}

                    {options.map((option, index) => {
                        var text = '';

                        if (optionLabel) {
                            for (let i = 0; i < optionLabel.length; i++) {
                                if (optionLabel[i].includes('.')) {
                                    text += getNestedPropertyValue(option, optionLabel[i]) + ' ';
                                } else {
                                    text += option[optionLabel[i]] + ' ';
                                }
                            }
                        } else {
                            text = option.name;
                        }

                        return (
                            <div
                                key={index}
                                className={`BTDSelectField__option ${selectedIndex === index ? 'selected' : ''}`}
                                onClick={() => handleOptionClick(option.id)}
                            >
                                {text}
                                {value === option.id ? <span> &#10003;</span> : null}
                            </div>
                        );
                    })}
                </div>
            </div>
                {error && <p className='BTDSelectField__error'>{error}</p>}
        </div>
    );
};

BTDSelectField.propTypes = {
    blankOption: PropTypes.bool,
    className: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    height: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    optionLabel: PropTypes.array,
    options: PropTypes.array,
    required: PropTypes.bool,
    startIcon: PropTypes.node,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string,
};

BTDSelectField.defaultProps = {
    blankOption: true,
    className: '',
    description: null,
    error: null,
    height: 'normal',
    label: '',
    onChange: () => {
        alert('onChange is not set!');
    },
    optionLabel: null,
    options: [],
    required: false,
    startIcon: null,
    style: {},
    value: null,
    width: 'large',
};

export default BTDSelectField;
