// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import CrossCircleIcon from 'components/BTDUI/icons/CrossCircleIcon';
import EditCircleIcon from 'components/BTDUI/icons/EditCircleIcon';
import UserEditScreen from 'modules/Users/screens/Edit';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const UserListItem = ({ email, id, isLoading, name, onChange }) => {

	const { destroy } = useApi('/users/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		if( await destroy('user', 'User Deleted!') ) {

			onChange();
		}

    }

	return (
		<>
		<div className="UserListItem card">

			<p className="UserListItem__name">{! isLoading ? name : <BTDSkeleton />}</p>

			<p className="UserListItem__email">{! isLoading ? email : <BTDSkeleton />}</p>

			<span className="UserListItem__edit" onClick={() => {setFormActive(true);}}><EditCircleIcon height='20px' style={{cursor: 'pointer', transform: 'translateY(1px)'}} /></span>
			
			<span className="UserListItem__delete" onClick={() => {deleteItem()}}><CrossCircleIcon colour='#e02323' height='20px' style={{cursor: 'pointer', transform: 'translateY(1px)'}} /></span>

		</div>

		{formActive &&
			<UserEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
			/>
		}
		</>
	)
}

UserListItem.propTypes = {
	code: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	onChange: PropTypes.func,
	placements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	placement_mode: PropTypes.string,
	rate: PropTypes.number
}

UserListItem.defaultProps = {
	code: '',
	id: null,
	isLoading: false,
	onChange: () => {'onChange is not set!'},
	placements: [],
	placement_mode: '',
	rate: 0
}

export default UserListItem;