// Packages
import { useEffect, useState } from 'react';

// Components
import AdminLayout from 'layouts/Admin';
import BookingsList from 'modules/Bookings/components/List';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDPagination from 'components/BTDUI/BTDPagination';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const BookingListScreen = () => {

    const [bookings, setBookings] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');

    const { data: items, isLoading, meta, onChange } = useApi('/orders?page=' + page);
    const { data: searchItems, isLoading: searchLoading, get: search } = useApi('/orders/search?query=' + query, false);

    useEffect(() => {

        if(query && query !== '') {

            setBookings(searchItems)
        }
        else {

            setBookings(items)
        }
    }, [items, searchItems])

    return(
        <AdminLayout>

            <h2 style={{marginTop: '35px'}}>Bookings</h2>

            <div className='BookingList__search'>

                <form 
                    className='BookingList__search__form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        setPage(1);
                        search();
                    }}
                >
                    <div style={{maxWidth: 'max-content'}}>
                        <span className='BookingList__search__form__text'>Search by organisation (hit enter):{<>&nbsp;&nbsp;&nbsp;</>}</span>
                    </div>
                    <div style={{marginRight: '20px', maxWidth: '400px', width: '100%'}}>
                        <input className='BookingList__search__form__input' onChange={(e) => {setQuery(e.target.value)}}/>
                    </div>
                </form>

                <BTDButton
                    className='BookingList__search__button'
                    label='Create Booking'
                    url='/admin/bookings/create'
                    size='small'
                />
            </div>


            {meta && meta.length !== 0 && searchItems.length === 0 &&
                <p style={{marginBottom: '40px'}}>Showing bookings {meta.from} to {meta.to} of {meta.total}</p>
            }
            
            <BookingsList 
                isLoading={isLoading || searchLoading}  
                items={bookings} 
            />

            {meta && meta.length !== 0 && searchItems.length === 0 &&
                <BTDPagination
                    currentPage={meta.current_page}
                    onChange={(value) => {
                        setPage(value)
                        onChange()}
                    }
                    totalPages={Math.ceil(meta.total / meta.per_page)}
                    style={{margin: '20px auto 0 auto', width: 'max-content'}}
                />
            }

        </AdminLayout>
    )

}

export default BookingListScreen;