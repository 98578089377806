// Packages
import { Link } from "react-router-dom";

// Components
import PublicLayout from "layouts/Public";

// other
import './styles.css';
import BTDButton from "components/BTDUI/BTDButton";

const ThankYouScreen = () => {

    return(
        <PublicLayout>
            <div className="ThankYouScreen">

                <h3 className="ThankYouScreen__message">Thank you for submitting your ANZA advertising request, a member of our team will be in touch with you shortly.</h3>
        
                <BTDButton 
                    label="Back to booking form"
                    url="/" 
                    style={{margin: '0 auto'}}
                />
            </div>
        </PublicLayout>
    )
}

export default ThankYouScreen;