// Packages
import { useState } from 'react';

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import PublicLayout from 'layouts/Public';

// Other
import '../styles.css';
import useApi from 'hooks/useApi';

const ForgotPasswordScreen = () => {

    const { post } = useApi('/forgot-password', false);

    const [email, setEmail] = useState('');

    return(
        <PublicLayout>
            <div className='LoginScreen'>

                <div className='LoginScreen__main'>

                    <p style={{marginBottom: '20px'}}>Forgotten your password? Enter your email address and receive a link to reset it.</p>

                    <BTDTextField
                        autocomplete={true}
                        label='Email Address'
                        onChange={(value) => setEmail(value)}
                        style={{backgroundColor: '#fff'}}
                        value={email}
                    />

                    <BTDButton
                        formButton={true}
                        label='Submit'
                        onClick={() => post({email: email}, 'We have emailed your password reset link.')}
                    />
                </div>
            </div>
        </PublicLayout>

    )

}

export default ForgotPasswordScreen;